import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Image } from 'react-bootstrap';
import catBlue from '../assets/cat-blue.svg';



export default function About() {
    
    return (
        <>
            <Helmet>
                <title>User Guide - bocs</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='pb-5 ps-0 pe-0 d-flex justift-content-center align-items-center bg-palette-orange' style={{ paddingTop: 100, minHeight: '100vh' }} id="hero-userguide">
                <Container fluid='lg' className='text-white'>
                    <Row>
                        <Col lg={5} md={7} sm={12}>
                            <h1>Innovative Strategies for Effective Learning and Collaboration</h1>
                            <p className='lead'>
                                Our workshops blend targeted preparatory activities, collaborative sessions, and engaging side quests to create a rich learning experience. Participants start with pre-work to align goals, then dive into dynamic problem-solving sessions, and reinforce their learning with fun, interactive challenges that drive continuous improvement.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0 bg-palette-blue'>
                <Container fluid='lg' className='text-center'>
                    <h1 className='text-palette-lightblue'>Why Consultant Collaboration Matters</h1>

                    <Row className='mt-5 text-start align-items-center'>
                        <Col sm={12} md={6} lg={6} className='mb-4'>
                            <div className='pt-5 ps-5 pe-5 bg-palette-orange' style={{ borderRadius: 10 }}>
                                <h1>1.</h1>
                                <h3>Preparatory<br />activities</h3>

                                <p className='text-end mt-5'>
                                    <Image src={catBlue} width={50} className='mb-4' />
                                </p>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={6} className='mb-5'>
                            <p className='text-palette-lightblue max-width-80'>
                                Preparatory activities are essential to ensure that participants are aligned and ready to engage fully in a workshop. These activities serve several key purposes: they help save time by reducing the need for extensive explanations during the workshop, prepare participants mentally for the tasks ahead, and foster a sense of individual responsibility and independent reflection.
                                <br/>
                                These preparatory activities ensure that participants come to the workshop ready to engage, with a shared understanding of the material and a mindset conducive to learning and collaboration. This preparation not only saves time but also enriches the overall experience by allowing for deeper, more meaningful discussions and activities during the workshop itself.
                            </p>
                        </Col>
                    </Row>

                    <Row className='mt-5 text-start align-items-center desktop-reverse'>
                        <Col sm={12} md={6} lg={6} className='mb-4'>
                            <div className='pt-5 ps-5 pe-5 bg-palette-orange' style={{ borderRadius: 10 }}>
                                <h1>2.</h1>
                                <h3>Workshop<br />session</h3>

                                <p className='text-end mt-5'>
                                    <Image src={catBlue} width={50} className='mb-4' />
                                </p>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={6} className='mb-5'>
                            <p className='text-palette-lightblue max-width-80'>
                                Workshop sessions are dynamic, collaborative environments designed to tackle specific challenges and produce tangible, actionable solutions. These sessions are structured to maximize participant engagement and facilitate active learning through hands-on activities and group problem-solving exercises.
                                <br/>
                                The primary goal of a workshop session is to collaboratively address a challenge that is relevant to the participants’ interests or professional needs. By working together, participants can pool their diverse skills, perspectives, and experiences to develop innovative solutions. This collective approach not only enhances the quality of the outcomes but also fosters a sense of shared ownership and accountability among the participants.
                                <br/>
                                In a workshop session, the process of breaking down complex ideas into more manageable components is central. Participants engage in discussions, brainstorming sessions, and interactive exercises that deconstruct the challenge into smaller, more understandable parts. This methodical approach enables participants to better grasp the intricacies of the issue at hand and identify practical, effective solutions.
                            </p>
                        </Col>
                    </Row>

                    <Row className='mt-5 text-start align-items-center'>
                        <Col sm={12} md={6} lg={6} className='mb-4'>
                            <div className='pt-5 ps-5 pe-5 bg-palette-orange' style={{ borderRadius: 10 }}>
                                <h1>3.</h1>
                                <h3>Side<br />quests</h3>

                                <p className='text-end mt-5'>
                                    <Image src={catBlue} width={50} className='mb-4' />
                                </p>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={6} className='mb-5'>
                            <p className='text-palette-lightblue max-width-80'>
                                Side quests are engaging, supplementary activities integrated into a learning experience to reinforce key concepts in a fun and interactive way. These activities are designed to complement the main content, offering participants a chance to apply what they’ve learned in a more relaxed and often creative context.
                                <br/>
                                The primary function of side quests is to enhance retention of the material. By engaging in these activities, participants revisit and practice the concepts they’ve encountered, which helps solidify their understanding. This repetition, framed within enjoyable and often gamified tasks, makes the learning process feel less like work and more like an adventure, increasing motivation and involvement.
                                <br/>
                                Beyond reinforcing knowledge, side quests also serve to extend the learning experience beyond the core sessions. They offer opportunities for participants to explore topics in greater depth or from different perspectives, which can lead to a richer and more nuanced understanding. Whether through puzzles, challenges, or collaborative projects, these quests provide an additional layer of learning that keeps participants engaged even after the main session has concluded.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};