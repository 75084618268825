import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Image, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import mockup2 from '../assets/2_Mockup02.jpg';
import catBlue from '../assets/cat-blue.svg';



export default function Home() {

    return (
        <>
            <Helmet>
                <title>Intro - bocs</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='pb-5 ps-0 pe-0 d-flex justift-content-center align-items-center bg-palette-blue' style={{ paddingTop: 100, minHeight: '100vh' }} id="hero-home">
                <Container fluid='lg' className='text-white'>
                    <Row>
                        <Col lg={5} md={7} sm={12}>
                            <h1>Ready to take your team's skills to the next level?</h1>
                            <p className='lead'>
                                At <b>bocs</b>, we design ready-to-use workshop kits that streamline training, empower teams, and deliver real results. Whether you’re looking to enhance creativity, improve collaboration, or increase productivity, <b>bocs</b> provides the tools you need to boost your business.
                            </p>

                            <div className='mt-5'>
                                <Link to='/coming_soon'>
                                    <span className='hero-call-to-action' role='button'>
                                        Which <b>bocs</b> is Right for You? <FontAwesomeIcon icon="arrow-right" size={'1x'} className='ms-2' color='#fff' />
                                    </span>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <p style={{ backgroundColor: '#18223b' }} className="marquee m-0">
                <span>Ci hai conosciuti al Noi Tech Park durante lo Slush? <u><Link to="coming_soon" className='link-light'>Scrivici qua</Link></u></span>
            </p>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0 bg-palette-lightblue-gradient'>
                <Container fluid='lg' className='text-dark'>
                    <Card style={{ border: 0, borderRadius: 10 }} className='bg-white'>
                        <Card.Body className='p-5'>
                            <Row className='align-items-center'>
                                <Col md={6} sm={12}>
                                    <Image src={mockup2} className='max-width-80' width={'100%'} style={{ borderRadius: 10 }} />
                                </Col>
                                <Col md={6} sm={12} className='mt-3 mb-3'>
                                    <h1>Our Focus</h1>
                                    <p>
                                        From the heart of South Tyrol, <b>bocs</b>, is changing the way businesses approach training. Our ready-to-use workshop kits are crafted by experienced consultants with years of expertise in their fields. These kits are designed to make training sessions more engaging and effective, without the need for complicated preparation. Whether you’re looking to enhance teamwork, spark creativity, or improve productivity, <b>bocs</b> provides everything you need to achieve real results quickly and easily.
                                    </p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>


                    <Row className='align-items-stretch mt-5'>
                        <Col md={6} sm={12} className='mb-5'>
                            <Card style={{ border: 0, height: '100%', borderRadius: 10 }} className='bg-palette-pink'>
                                <Card.Body className='p-5 text-white'>
                                    <FontAwesomeIcon icon="lightbulb" size={'4x'} className='mb-5' />
                                    <h2>Innovative Solutions:</h2>
                                    <p className='max-width-80'>
                                        <b>bocs</b> creates ready-to-use workshop kits that make training sessions more exciting and effective. Our kits are designed to help businesses improve and grow by offering practical and creative training tools that work for teams of all sizes. We believe that training should be easy to access and should make a real difference.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} sm={12} className='mb-5'>
                            <Card style={{ border: 0, height: '100%', borderRadius: 10 }} className='bg-palette-orange'>
                                <Card.Body className='p-5 text-white'>
                                    <FontAwesomeIcon icon="bullseye" size={'4x'} className='mb-5' />
                                    <h2>Proven Impact:</h2>
                                    <p className='max-width-80'>
                                        <b>bocs</b> kits are designed to deliver real results. They help teams become more creative, work better together, and solve problems more effectively. Our kits are made to ensure that what your team learns can be put into action right away, leading to noticeable improvements in performance.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                    <Row className='align-items-stretch'>
                        <Col md={6} sm={12} className='mb-5'>
                            <Card style={{ border: 0, height: '100%', borderRadius: 10 }} className='bg-palette-orange'>
                                <Card.Body className='p-5 text-white'>
                                    <FontAwesomeIcon icon="user-check" size={'4x'} className='mb-5' />
                                    <h2>Ease of Use:</h2>
                                    <p className='max-width-80'>
                                        Our kits are very easy to use, even if you’ve never led a workshop before. Each kit comes with everything you need, including clear instructions and all the materials. This means anyone can run a successful training session with minimal preparation, saving time and effort.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} sm={12} className='mb-5'>
                            <Card style={{ border: 0, height: '100%', borderRadius: 10 }} className='bg-palette-pink'>
                                <Card.Body className='p-5 text-white'>
                                    <FontAwesomeIcon icon="scale-balanced" size={'4x'} className='mb-5' />
                                    <h2>Cost-Effective:</h2>
                                    <p className='max-width-80'>
                                        <b>bocs</b> offers high-quality training at a lower cost than traditional methods. Our kits save money by cutting out the need for expensive trainers and long preparation times. This makes it easier for businesses to get the training they need without spending too much.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0 bg-palette-blue'>
                <Container fluid='lg' className='text-center'>
                    <h1 className='text-palette-lightblue'>Our Method</h1>

                    <Row className='mt-5 text-start'>
                        <Col sm={12} md={4} lg={4} className='mb-5'>
                            <div className='pt-5 ps-5 pe-5 bg-palette-lightblue' style={{ borderRadius: 10 }}>
                                <h1>1.</h1>
                                <h3>Preparatory<br />activities</h3>

                                <p className='text-end mt-5'>
                                    <Image src={catBlue} width={50} className='mb-4' />
                                </p>
                            </div>
                            <p className='grid-cat-desc text-palette-lightblue'>
                                Streamline the workshop by getting everyone on the same page. These activities save time, prepare participants mentally, and encourage independent reflection.
                            </p>
                        </Col>
                        <Col sm={12} md={4} lg={4} className='mb-5'>
                            <div className='pt-5 ps-5 pe-5 bg-palette-lightblue' style={{ borderRadius: 10 }}>
                                <h1>2.</h1>
                                <h3>Workshop<br />session</h3>

                                <p className='text-end mt-5'>
                                    <Image src={catBlue} width={50} className='mb-4' />
                                </p>
                            </div>
                            <p className='grid-cat-desc text-palette-lightblue'>
                                Collaboratively solve a challenge and deliver actionable solutions. This session promotes active learning, breaks down complex ideas, and encourages teamwork.
                            </p>
                        </Col>
                        <Col sm={12} md={4} lg={4} className='mb-5'>
                            <div className='pt-5 ps-5 pe-5 bg-palette-lightblue' style={{ borderRadius: 10 }}>
                                <h1>3.</h1>
                                <h3>Side<br />quests</h3>

                                <p className='text-end mt-5'>
                                    <Image src={catBlue} width={50} className='mb-4' />
                                </p>
                            </div>
                            <p className='grid-cat-desc text-palette-lightblue'>
                                Fun activities to reinforce learning. They boost retention, extend the learning experience, and support continuous improvement.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <ParallaxBanner style={{ height: '300px' }}
                layers={[
                    { image: mockup2, speed: -25 },
                    /*
                    {
                        speed: -15,
                        children: (
                            <div className="position-absolute top-0 start-0 bottom-0 end-0 d-flex justify-content-center align-items-center flex-column">

                            </div>
                        ),
                    },
                    */
                ]}
                className="aspect-[2/1]"
            />


            <Container fluid className='pt-5 pb-5 ps-0 pe-0 bg-palette-blue'>
                <Container fluid='lg' className='text-start text-palette-lightblue'>
                    <Row className='align-items-stretch mt-5'>
                        <Col md={6} sm={12} className='mb-5'>
                            <Card style={{ border: 0, height: '100%', borderRadius: 10 }} className='bg-palette-lightblue'>
                                <Card.Header className='ps-5 pe-5 pt-5 pb-3'>
                                    <FontAwesomeIcon icon="rocket" size={'2x'} className='mb-3' />
                                    <h2>Mission: </h2>
                                </Card.Header>
                                <Card.Body className='p-5'>
                                    <p className='max-width-80'>
                                        Our mission is simple: to revolutionize training with ready-to-use workshop kits that are engaging, efficient, and effective.
                                        <br/>
                                        We believe in empowering teams and businesses of all sizes with tools that drive real results and foster growth.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} sm={12} className='mb-5'>
                            <Card style={{ border: 0, height: '100%', borderRadius: 10 }} className='bg-palette-lightblue'>
                                <Card.Header className='ps-5 pe-5 pt-5 pb-3'>
                                    <FontAwesomeIcon icon="eye" size={'2x'} className='mb-3' />
                                    <h2>Vision: </h2>
                                </Card.Header>
                                <Card.Body className='p-5'>
                                    <p className='max-width-80'>
                                        <b>Innovation</b>: We constantly seek new ways to enhance learning experiences and deliver value.<br/>
                                        <b>Collaboration</b>: We work closely with consultants and clients to tailor solutions that meet unique needs.<br/>
                                        <b>Integrity</b>: We are dedicated to providing high-quality, reliable training resources.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>


        </>
    )

};