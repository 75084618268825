import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar, Image, Row, Col, Button } from 'react-bootstrap';
import logo from '../../assets/logo.png';



export default function HeaderBlock() {

    let location = useLocation();

    useEffect(() => {
        console.log('location: ', location)
        //var element = document.getElementById("app-container");
        //element.setAttribute("data-page", location.pathname);
        var header = document.getElementById('header');
        header.setAttribute("data-menu-mobile", "close");
    }, [location]);


    useLayoutEffect(() => {
        //document.documentElement.scrollTo(0, 0);
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [location.pathname]);


    //menu mobile
    function openMenuMobile() {
        var header = document.getElementById('header');
        var attr = header.getAttribute("data-menu-mobile");
        if(attr === 'open') {
            header.setAttribute("data-menu-mobile", "close");
        }
        else {
            header.setAttribute("data-menu-mobile", "open");
        }
    }



    return (
        <Container fluid={'lg'} id="header" className='mt-4 p-0' data-menu-mobile='close'>
            <Row className='align-items-center' id="header-row-container">
                <Col xs={4} md={3} lg={2} className='m-0 p-0'>
                    <Container fluid={'lg'} className='p-0 ms-0 overflow-hidden text-start'>
                        <Link to="/">
                            <Image src={logo} width={60} />
                        </Link>
                    </Container>
                </Col>
                <Col xs={4} md={0} className='only-mobile'>
                    <Container fluid={'lg'} className='text-center'>
                        <span id='burger-menu' role='button' onClick={() => openMenuMobile()}>
                            <span className='line-burger-menu'></span>
                            <span className='line-burger-menu'></span>
                            <span className='line-burger-menu'></span>
                        </span>
                    </Container>
                </Col>
                <Col xs={0} md={6} lg={8} className='only-desktop'>
                    <Navbar id='header-navbar' className='p-0'>
                        <Container fluid className='text-center justify-content-center' id="header-menu">
                            <Nav>
                                <Nav.Link to="/" as={Link}>
                                    Home
                                </Nav.Link>
                                <Nav.Link to="/about" as={Link}>
                                    User Guide
                                </Nav.Link>
                                <Nav.Link to="/coming_soon" as={Link}>
                                    Look Inside
                                </Nav.Link>
                                <Nav.Link to="/creation" as={Link}>
                                    Creation
                                </Nav.Link>
                                <Nav.Link to="/shop" as={Link}>
                                    Buy Now
                                </Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar>
                </Col>
                <Col xs={4} md={3} lg={2} className='m-0 p-0'>
                    <Container fluid={'lg'} className='p-0 me-0 text-dark overflow-hidden text-end'>
                        <Link to="/contact">
                            <Button size='sm' variant={'danger'} id="header-button">Contact us</Button>
                        </Link>
                    </Container>
                </Col>
                <Col xs={12} md={0} className='only-mobile'>
                    <div id='menu-mobile' className='text-center'>
                        <div className='navbar-nav'>
                            <Nav>
                                <Nav.Link to="/" as={Link}>
                                    Home
                                </Nav.Link>
                                <Nav.Link to="/about" as={Link}>
                                    User Guide
                                </Nav.Link>
                                <Nav.Link to="/coming_soon" as={Link}>
                                    Look Inside
                                </Nav.Link>
                                <Nav.Link to="/creation" as={Link}>
                                    Creation
                                </Nav.Link>
                                <Nav.Link to="/shop" as={Link}>
                                    Buy Now
                                </Nav.Link>
                            </Nav>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};