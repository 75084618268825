import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import ContactFormSimple from '../components/ContactFormSimple';



export default function ComingSoon() {
    
    return (
        <>
            <Helmet>
                <title>Coming soon - bocs</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='pb-5 ps-0 pe-0 bg-palette-blue d-flex justift-content-center align-items-center' style={{ paddingTop: 150, minHeight: '100vh' }}>
                <Container fluid='lg' className='text-white text-start'>
                    <Row>
                        <Col sm={12} lg={6} className='mb-4'>
                            <h1>Want to discover the magic ingredients to take your team to the next level?</h1>
                            
                            <p className='lead'>
                                We know you can’t wait to open the bocs and see what you can find inside!
                            </p>
                            <p>
                                Stay tuned for something exciting that’s coming soon.
                                <br/>
                                Leave your contact details below, and we’ll keep you in the loop. You’ll be among the first to peek inside the bocs and discover all the surprises we have in store!
                            </p>
                        </Col>
                        <Col sm={12} lg={2}></Col>
                        <Col sm={12} lg={4} className='mb-4'>
                            <h1 className='mb-3'>Keep in touch!</h1>
                            <p>Leave your contact here:</p>

                            <ContactFormSimple />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};