import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Image } from 'react-bootstrap';
import notfound from '../assets/404.png';


export default function NotFound() {

    return (
        <>
            <Helmet>
                <title>Page Not Found - bocs</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='pb-5 ps-0 pe-0 bg-palette-blue' style={{ paddingTop: 150, minHeight: '100vh' }}>
                <Container fluid='lg' className='text-white text-center'>

                    <h1>Error 404</h1>
                    <h2 className='lead'>The page you were looking for doesn't exist.</h2>
                    <Image src={notfound} className="mt-5" width={'90%'} style={{ maxWidth: 600 }} />

                </Container>
            </Container>
        </>
    );
};