import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import instagram from '../../assets/instagram.svg';
import linkedin from '../../assets/linkedin.svg';



export default function FooterBlock() {


    return (
        <Container fluid className='p-3 text-white' id="footer">
            <Container fluid='md'>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <h4>Company</h4>
                        <div className='footer-widget pt-3'>
                            <p>
                                Bocs Srl.<br/>
                                Via Carlo De Vigili,<br/>
                                Bolzano, 39100<br/>
                                Italia
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <h4>Say hi</h4>
                        <div className='footer-widget pt-3'>
                            <p>
                                <i>Info</i>: <b><a className="link-light" href="mailto:hello@webocs.com">hello@webocs.com</a></b><br/>
                                <i>Sales</i>: <b><a className="link-light" href="mailto:silvia@webocs.com">silvia@webocs.com</a></b><br/>
                                <i>Operations</i>: <b><a className="link-light" href="mailto:salome@webocs.com">salome@webocs.com</a></b>
                            </p>
                            
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <h4>Follow Us</h4>
                        <div className='footer-widget pt-3'>
                            <p>
                                <Link to="/">
                                    <span className='footer-social-icon me-3'>
                                        <Image style={{filter:'invert(1)'}} src={linkedin} width={14} />
                                    </span>
                                </Link>
                                <a href='https://www.instagram.com/we.bocs/?igsh=MWcyYmJwZndoZWxndA%3D%3D' target='_blank'>
                                    <span className='footer-social-icon me-3'>
                                        <Image style={{filter:'invert(1)'}} src={instagram} width={14} />
                                    </span>
                                </a>
                            </p>
                        </div>
                    </Col>
                    
                </Row>
            </Container>
            <Container fluid='md' className='mt-5 text-center'>
                <p className='text-secondary'>© 2024 Bocs Srl. | P.iva 00000000000</p>
            </Container>
        </Container>
    );
};