import React, { useState } from 'react';
import { Form, Alert, Button } from 'react-bootstrap';
import axios from 'axios';


export default function ContactForm() {

    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState('');
    const [company, setCompany] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    function sendMessage() {
        if (name.length > 0 && email.length > 0 && message.length > 0) {

            const headers = {
                "Content-Type": "application/json"
            };
            var body = {
                company: company,
                name: name,
                email: email,
                message: message
            }
            axios.post('https://api.webocs.com/mail/contact.php', body, { headers }).then(response => {
                console.log(response)
            }).catch(error => {
                console.error('There was an error!', error);
            });

            setErrors('')
            setSuccess('Message sent successfully.')
        }
        else {
            setSuccess('')
            setErrors('Please complete all fields before sending message.')
        }

        setTimeout(() => {
            setSuccess('')
            setErrors('')
        }, 3000);
    }



    return (
        <>
            <div style={{ maxWidth: 600 }} className='m-auto'>
                <Form.Control size="lg" type="text" placeholder="Company name" className='mb-3' value={company} onChange={(event) => setCompany(event.target.value)} />

                <Form.Control size="lg" type="text" placeholder="Name" className='mb-3' value={name} onChange={(event) => setName(event.target.value)} />

                <Form.Control size="lg" type="text" placeholder="Email" className='mb-3' value={email} onChange={(event) => setEmail(event.target.value)} />

                <Form.Control size="lg" as="textarea" rows={3} placeholder="Message" className='mb-5' value={message} onChange={(event) => setMessage(event.target.value)} />


                <Button variant='danger' onClick={() => sendMessage()}>
                    Send message
                </Button>

                {errors.length > 0 && (
                    <Alert className='mt-5' variant='danger'>{errors}</Alert>
                )}

                {success.length > 0 && (
                    <Alert className='mt-5' variant='success'>{success}</Alert>
                )}
            </div>
        </>
    );
};