import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Image } from 'react-bootstrap';
import catBlue from '../assets/cat-blue.svg';
import sticker from '../assets/sticker.png';
import ContactFormSimple from '../components/ContactFormSimple';


export default function Creation() {

    return (
        <>
            <Helmet>
                <title>Creation - bocs</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='pb-5 ps-0 pe-0 d-flex justift-content-center align-items-center bg-palette-pink' style={{ paddingTop: 100, minHeight: '100vh' }} id="hero-creation">
                <Container fluid='lg' className='text-white'>
                    <Row>
                        <Col lg={5} md={7} sm={12}>
                            <h1>Expertly Crafted with Consultant Collaboration</h1>
                            <p className='lead'>
                                At <b>bocs</b>, we understand that high-quality training requires more than just well-designed materials; it demands expertise and insight. That’s why our workshop kits are created in close collaboration with experienced consultants.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0 bg-palette-blue'>
                <Container fluid='lg' className='text-center'>
                    <h1 className='text-palette-lightblue'>Why Consultant Collaboration Matters</h1>

                    <Row className='mt-5 text-start'>
                        <Col sm={12} md={4} lg={4} className='mb-5'>
                            <div className='pt-5 ps-5 pe-5 bg-palette-pink' style={{ borderRadius: 10 }}>
                                <h1>1.</h1>
                                <h3>Deep<br />Expertise</h3>

                                <p className='text-end mt-5'>
                                    <Image src={catBlue} width={50} className='mb-4' />
                                </p>
                            </div>
                            <p className='grid-cat-desc text-palette-lightblue'>
                                <b>What It Means :</b><br />
                                Our kits are developed alongside top industry consultants who bring years of specialized knowledge and practical experience to the table.
                                <br /><br />
                                <b>Benefits for Your Business :</b><br />
                                Relevant Content: Access to cutting-edge strategies and insights that address real-world business challenges.
                                <br />
                                Best Practices: Implementation of proven methodologies that drive results.
                            </p>
                        </Col>
                        <Col sm={12} md={4} lg={4} className='mb-5'>
                            <div className='pt-5 ps-5 pe-5 bg-palette-pink' style={{ borderRadius: 10 }}>
                                <h1>2.</h1>
                                <h3>Tailored<br />Solutions</h3>

                                <p className='text-end mt-5'>
                                    <Image src={catBlue} width={50} className='mb-4' />
                                </p>
                            </div>
                            <p className='grid-cat-desc text-palette-lightblue'>
                                <b>What It Means :</b><br />
                                We work with consultants to tailor our workshop kits to the specific needs of various industries and business functions.
                                <br /><br />
                                <b>Benefits for Your Business :</b><br />
                                Customized Training : Workshops designed to address your unique challenges and goals.
                                <br />
                                Effective Outcomes: Solutions that are directly applicable and immediately valuable to your team.
                            </p>
                        </Col>
                        <Col sm={12} md={4} lg={4} className='mb-5'>
                            <div className='pt-5 ps-5 pe-5 bg-palette-pink' style={{ borderRadius: 10 }}>
                                <h1>3.</h1>
                                <h3>Proven<br />Effectiveness</h3>

                                <p className='text-end mt-5'>
                                    <Image src={catBlue} width={50} className='mb-4' />
                                </p>
                            </div>
                            <p className='grid-cat-desc text-palette-lightblue'>
                                <b>What It Means :</b><br />
                                Our consultant partners test and refine our materials to ensure they are both practical and impactful.
                                <br /><br />
                                <b>Benefits for Your Business:</b><br />
                                High Quality: Training solutions that are rigorously vetted and continually improved.
                                <br />
                                Measurable Results: Kits that deliver real, actionable outcomes and drive business success.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0 bg-palette-blue'>
                <Container fluid='lg' className='text-center text-palette-lightblue'>
                    <h1>Our Collaboration Process</h1>
                    <p style={{ maxWidth: 600, margin: 'auto' }}>These steps ensure that every bocs kit you purchase is built on a foundation of expert knowledge, meticulously designed, and tested for effectiveness, offering you a reliable and engaging training solution.</p>
                    <div style={{ maxWidth: 700, margin: 'auto' }} className='text-start' id="creation-sticky">
                        <div className='container-sticky'>
                            <div className='sticky-col'>
                                <div>
                                    <h2>Sharing<br />Knowledge</h2>
                                    <FontAwesomeIcon icon="brain" size={'4x'} className='only-desktop' color='rgb(252, 121, 176)' />
                                </div>
                            </div>
                            <div className='scroll-col'>
                                <p>
                                    The creation of each bocs starts with a deep collaboration between our team and industry-leading consultants. We work closely with these experts to extract their valuable knowledge, understanding their methodologies, and the specific challenges they address in their field. This collaborative ideation ensures that the core content of the bocs is not just generic but tailored, reflecting the nuanced insights of those who have hands-on experience.
                                </p>
                            </div>
                        </div>

                        <div className='container-sticky'>
                            <div className='sticky-col'>
                                <div>
                                    <h2>Turning Knowledge<br />into Kits</h2>
                                    <FontAwesomeIcon icon="box" size={'4x'} className='only-desktop' color='rgb(252, 121, 176)' />
                                </div>
                            </div>
                            <div className='scroll-col'>
                                <p>
                                    Once we have gathered the consultant’s insights, we meticulously design the bocs to transform their expert knowledge into an interactive and engaging workshop format. This process involves translating complex ideas into easily digestible and actionable content. We also include interactive elements, such as exercises and side quests, to ensure the training is not only informative but also engaging, making it easier for participants to absorb and apply the knowledge.
                                </p>
                            </div>
                        </div>

                        <div className='container-sticky'>
                            <div className='sticky-col'>
                                <div>
                                    <h2>Perfecting the<br />Training</h2>
                                    <FontAwesomeIcon icon="dumbbell" size={'4x'} className='only-desktop' color='rgb(252, 121, 176)' />
                                </div>
                            </div>
                            <div className='scroll-col'>
                                <p>
                                    Before a bocs is finalized, it undergoes a rigorous testing phase with real users. This step is crucial to ensure the content is practical, effective, and easy to follow. Based on feedback, we refine and enhance the bocs, making any necessary adjustments to ensure that it meets the highest standards of quality and effectiveness. This thorough process guarantees that when you purchase a bocs, you’re getting a well-vetted product that delivers proven results.
                                </p>
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0 bg-white'>
                <Container fluid='lg' className='text-center text-palette-blue'>
                    <Image src={sticker} width={300} className='mb-5' />
                    <h1>Are you a consultant?</h1>
                    <p className='lead mb-5' style={{ maxWidth: 600, margin: 'auto' }}>
                        Interested in collaborating with us? Leave your contact details below, and let's explore how we can work together to create impactful workshop solutions
                    </p>
                    <ContactFormSimple/>
                </Container>
            </Container>
        </>
    );
};