import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Form, Alert, Button } from 'react-bootstrap';
import ContactForm from '../components/ContactForm';
import axios from 'axios';


export default function Contact() {

    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState('');
    const [company, setCompany] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    function sendMessage() {
        if(name.length > 0 && email.length > 0 && message.length > 0) {
            
            const headers = {
                "Content-Type": "application/json"
            };
            var body = {
                company: company,
                name: name,
                email: email,
                message: message
            }
            axios.post('https://api.webocs.com/mail/contact.php', body, {headers}).then(response => {
                console.log(response)
            }).catch(error => {
                console.error('There was an error!', error);
            });

            setErrors('')
            setSuccess('Message sent successfully.')
        }
        else {
            setSuccess('')
            setErrors('Please complete all fields before sending message.')
        }

        setTimeout(() => {
            setSuccess('')
            setErrors('')
        }, 3000);
    }



    return (
        <>
            <Helmet>
                <title>Contact Us - We Bocs</title>
                <meta name="description" content="" />
            </Helmet>

            <Container fluid className='pb-5 ps-0 pe-0 d-flex justift-content-center align-items-center bg-palette-blue' style={{ paddingTop: 150, minHeight: '100vh' }}>
                <Container fluid='lg' className='text-white text-center'>

                    <h1>Curious to learn more?</h1>
                    <p className='lead' style={{ maxWidth: 500, margin: 'auto' }}>Leave your contact details below, and we'll reach out with everything you need to know!</p>

                    <hr className='mt-5 mb-5'/>


                    <ContactForm />
                </Container>
            </Container>
        </>
    );
};